<script lang="ts">export let name = null;
export let en = null;
export let more = null;
export let size = null;
export let kind = null;
export let color = null;
export let background = null;
</script>

<section
  class="section__{size} section__{kind} section__{color}"
  class:section__image="{background}"
  style="background-image: url({background})"
>
  <div class="contents">
    <slot></slot>
  </div>
</section>

<style type="text/scss">section {
  position: relative;
  z-index: 5;
  padding: 60px 0;
  background-size: cover;
  background-position: 50% 50%;
}

.section__top {
  padding: 60px 0 0 0;
}
.section__top-small {
  padding: 45px 0 0 0;
}
.section__bottom {
  padding: 0 0 60px 0;
}
.section__large {
  padding: 120px 0;
}
.section__small {
  padding: 45px 0;
}
.section__narrow {
  padding: 30px 0;
}
.section__gapless {
  padding: 0;
}
.section__image h2, .section__image p {
  color: #ffffff;
}
.section__image:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.section__image * {
  position: relative;
  z-index: 2;
}

@media only screen and (max-width: 768px) {
  section {
    padding: 45px 0;
  }

  .section__top {
    padding: 45px 0 0 0;
  }
  .section__top-small {
    padding: 30px 0 0 0;
  }
  .section__bottom {
    padding: 0 0 45px 0;
  }
  .section__large {
    padding: 60px 0;
  }
  .section__small {
    padding: 30px 0;
  }
  .section__narrow {
    padding: 15px 0;
  }
  .section__gapless {
    padding: 0;
  }
}</style>
