<script lang="ts"></script>

<div>
  <slot></slot>
</div>

<style type="text/scss">div {
  max-width: 860px;
  margin: 0 auto;
}</style>
