<script lang="ts">import Wrap from '$components/slot/Wrap.svelte';
import { basePath, menu } from '$config/navigation';
import { folded } from './index';
export let segment;
folded.set(true);
function link(href) {
    return () => {
        folded.set(true);
        location.href = basePath + href;
    };
}
function toggle() {
    folded.set(!$folded);
}
function fold() {
    folded.set(true);
}
</script>

<nav class:expanded={!$folded} on:click={fold}>
  <Wrap fullOnMobile>
    <ul>
      {#each menu as m}
        <li>
          <a href="{basePath + m.href}" class:selected="{m.segment === segment}">{m.name}</a>
        </li>
      {/each}
    </ul>
  </Wrap>
</nav>

<div class="expand" class:expanded={!$folded} on:click={toggle}>
  <span></span>
  <span></span>
  <span></span>
</div>

<style type="text/scss">nav {
  position: relative;
  z-index: 100;
  background: linear-gradient(0deg, #00396d, #1e528d 70%);
}

ul {
  display: flex;
  align-items: center;
  list-style: none;
}

li {
  flex-grow: 1;
  width: 100%;
}

button, a {
  display: block;
  width: 100%;
  padding: 1.5rem 0;
  text-decoration: none;
  color: #ffffff;
  font-family: Arial, "Rounded Mplus 1p";
  text-align: center;
  transition-duration: 0.3s;
}
button.selected, button:hover, a.selected, a:hover {
  background: linear-gradient(0deg, #002c5a, #19467d 70%);
}

@media only screen and (max-width: 1069px) {
  button, a {
    font-size: 0.8rem;
  }
}
.expand {
  position: fixed;
  z-index: 101;
  display: none;
  top: 10px;
  right: 10px;
  width: 50px;
  height: 40px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.75);
}
.expand span {
  position: absolute;
  left: 7px;
  display: inline-block;
  width: 36px;
  height: 2px;
  background-color: #005ead;
  border-radius: 4px;
  transition-duration: 0.3s;
}
.expand span:nth-of-type(1) {
  top: 7px;
}
.expand span:nth-of-type(2) {
  top: 19px;
}
.expand span:nth-of-type(3) {
  bottom: 7px;
}
.expand.expanded span:nth-of-type(1) {
  -webkit-transform: translateY(12px) rotate(-45deg);
          transform: translateY(12px) rotate(-45deg);
}
.expand.expanded span:nth-of-type(2) {
  opacity: 0;
}
.expand.expanded span:nth-of-type(3) {
  -webkit-transform: translateY(-12px) rotate(45deg);
          transform: translateY(-12px) rotate(45deg);
}

@media only screen and (max-width: 768px) {
  nav {
    position: fixed;
    top: 0;
    left: 100vw;
    display: flex;
    align-items: center;
    width: 100vw;
    height: 100vh;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    transition-duration: 0.3s;
  }
  nav.expanded {
    -webkit-transform: translateX(-100vw);
            transform: translateX(-100vw);
  }

  ul {
    flex-direction: column;
  }

  button, a {
    font-size: 1rem;
  }

  .expand {
    display: inline-block;
  }
}</style>
